import { teamStepsMarkdown } from "@/assets/documentation/articles/createATeam";

export const customizeTeamHighlights = [
  {
    name: "Pay for member usage",
    markdown: `Help members get to work faster by having the team pay for everyone's usage. {% inlineRouterLink %}**Try it.**{% /inlineRouterLink %}
    `,
  },
  {
    name: "Share datapoints",
    markdown: `
  - Help members stay on the same page by sharing the specific safezone datapoints that give you an advantage. {% inlineRouterLink %}**Try it.**{% /inlineRouterLink %}
  - Tower Hunt also allows team members to automatically share safezone datapoints with the team, which can provide a simpler experience for some teams. {% inlineRouterLink %}**Try it.**{% /inlineRouterLink %}
  `,
  },
  {
    name: "Pool earnings",
    markdown: `Important datapoints are often obtained by teams working together. Even though one person keys the data into Tower Hunt, some teams may want to share in the upside. Tower Hunt understands this, which is why teams are able to utilize {% inlineRouterLink %}shared payout accounts{% /inlineRouterLink %} to pool their earnings. {% inlineRouterLink %}**Try it.**{% /inlineRouterLink %}
    `,
  },
];

export const gettingStartedForTowerHuntAdmins = {
  order: 0,
  name: "Getting started for team leaders",
  subcategory: "Getting started for Tower Hunt admins",
  markdown: `# Getting started for team leaders
  In Tower Hunt, teams make it easy for groups of users to share datapoints and earnings. Teams can also pay for the datapoint usage of their members. These features make teams a great way to deliver more value to the people you work with inside or outside of your company. If you haven't already, [create your Tower Hunt account](/register) and {% inlineRouterLink articleId="verify-your-email-address" %}verify your email address{% /inlineRouterLink %}.

  {% iconWellContainer iconName="rocket" iconBackgroundColor="bg-indigo-900" %}
  **In this guide, you'll learn how to:**
  - Create a team
  - Customize the team
  - Invite people and get them up to speed
  {% /iconWellContainer %}

  {% callout type="tip" marginStyles="mt-4" %}
  **Tip:** If you're newer to Tower Hunt, read {% inlineRouterLink articleId="what-is-tower-hunt" %}What is Tower Hunt?{% /inlineRouterLink %} for a general overview of how things work. Or, learn how to {% inlineRouterLink articleId="join-a-tower-hunt-team" %}join a Tower Hunt team{% /inlineRouterLink %}.
  {% /callout %}

  ## 1. Create a team

  ${teamStepsMarkdown}

  ## 2. Customize the team

  As a team creator, you'll hold the {% inlineRouterLink articleId="understand-the-primary-owner-role" %}Primary Owner{% /inlineRouterLink %} role. You'll have access to settings that can improve the user experience for each team member. Below are a few highlights.

  {% tabbedContainer tabs=$customizeTeamHighlights /%}

  ## 3. Invite people and get them up to speed

  Now that you've completed the basics to set up your team, you're ready to invite your coworkers and get them familiar with Tower Hunt. Here are a few tasks we recommend doing to get your coworkers up and running.

  {% tablessContainer %}
  1. Use this {% inlineRouterLink articleId="email-template-for-introducing-tower-hunt" %}customizable template to introduce Tower Hunt{% /inlineRouterLink %} to your team. If you already have a communication plan in place, include a link to our {% inlineRouterLink articleId="how-to-use-tower-hunt-your-quick-start-guide" %}quick start guide{% /inlineRouterLink %}.
  2. Read about the {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}types of roles in Tower Hunt{% /inlineRouterLink %} and think about whether you'll need any of them for billing or administrative purposes.
  3. {% inlineRouterLink %}Invite people to your team!{% /inlineRouterLink %}
  {% /tablessContainer %}

  ## Do more

  Tower Hunt is at its best when users make it their own. If you find your teammates continue to default to other tools, we have a few tips to help them transition to Tower Hunt.

  - **Diagram your properties:** Being able to see the physical nuances of a property is especially helpful in real estate because it is such a tactile asset class. With a property diagram in Tower Hunt, your team can start to organize work by where it is at the property, allowing people to gain additional context and data in a single view. Read our {% inlineRouterLink %}guide to property diagrams{% /inlineRouterLink %} to learn more.
  - **Add your best data:** Tower Hunt handles complex real estate details so that you can see what's really happening in a market, a property, or a deal. Those insights are amplified when they involve recent, hard-to-find datapoints. Tower Hunt automatically conceals private data that is essential to market competition, so encourage your teammates to contribute their best data to your team. Read our article about {% inlineRouterLink %}understanding safezone datapoints{% /inlineRouterLink %} to learn more.`,
};
